/**
 * @generated SignedSource<<e0a7351860eb29f59810bc88e9cb067e>>
 * @relayHash e310b5ac5a0ed6342712983038e14e0f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9f048755a95edc598aeba160bc98ed479ea5959e8410e5cbd927735a6e043cff

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from './pageLayout_projectsDirectoryLayout_RootQuery.graphql';

const node: PreloadableConcreteRequest<pageLayout_projectsDirectoryLayout_RootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "9f048755a95edc598aeba160bc98ed479ea5959e8410e5cbd927735a6e043cff",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

/**
 * @generated SignedSource<<b1a0f15f55ec38d73740db967b2857b7>>
 * @relayHash 5f357903f38d8eac106bdb4dbe673fc7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b51bcb5ee2b0b28a6adeef014d92fdb412b91df647d9ae0a9df699c56fe9cef

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListEmbedQuery } from './src_jiraBusinessListEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6b51bcb5ee2b0b28a6adeef014d92fdb412b91df647d9ae0a9df699c56fe9cef",
    "metadata": {},
    "name": "src_jiraBusinessListEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

/**
 * @generated SignedSource<<b3a768f8bceac86e6d88b83ea3544b90>>
 * @relayHash 882ea7f6e19079b5330aabdf1f43054b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b3ed98bf6a98f63709e833e766287d89a8bd00773d78496a49b25966b7b3bd32

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b3ed98bf6a98f63709e833e766287d89a8bd00773d78496a49b25966b7b3bd32",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

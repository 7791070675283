/**
 * @generated SignedSource<<cce7ca483f6a2c1eb5388795e9955292>>
 * @relayHash 4bab1436ae8b4bd9c5e4546ca4b67f08
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6cc61e008186941c093aac5e06b2a39504cdfd04d851c6a02947a537aa87a75a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListEmbedQuery } from './src_jiraSoftwareListEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraSoftwareListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6cc61e008186941c093aac5e06b2a39504cdfd04d851c6a02947a537aa87a75a",
    "metadata": {},
    "name": "src_jiraSoftwareListEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

/**
 * @generated SignedSource<<35cae17ea16180cdd043bdf1ecbcfed6>>
 * @relayHash 21de3735338c40328d20b9879f24e3ca
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b021f1f86af7a4bfe24b2c60c47d4b5fe6741a24c4f6e8a315eaad94dc95cfae

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListQuery } from './src_jiraSoftwareListQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraSoftwareListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b021f1f86af7a4bfe24b2c60c47d4b5fe6741a24c4f6e8a315eaad94dc95cfae",
    "metadata": {},
    "name": "src_jiraSoftwareListQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

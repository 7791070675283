/**
 * @generated SignedSource<<f248cb5687e0f2bd79bbfef1fd5e49a6>>
 * @relayHash 28a96673e8405ed121a00c6c4987b7d8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e3c81d36a9e6637bf91a396e5e6a9e5107f352b1718f6f9b20ac63f485749ddf

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewTimelineQuery } from './src_jiraBusinessOverviewTimelineQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e3c81d36a9e6637bf91a396e5e6a9e5107f352b1718f6f9b20ac63f485749ddf",
    "metadata": {},
    "name": "src_jiraBusinessOverviewTimelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

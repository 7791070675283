/**
 * @generated SignedSource<<e76ff44eb67cccd543661bfaac630c85>>
 * @relayHash 09060723bc0893cb0f9a21dc2353bccc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 60dc2b047f51b447b5b972b5bfde124e040a46b15be63e8337ebb05a87d88095

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavJiraInnerQuery } from './HorizontalNavJiraInnerQuery.graphql';

import isJiraCustomTemplatesUxEnabled_provider from '@atlassian/jira-relay-provider/src/jira-custom-templates-ux-fg.relayprovider';

const node: PreloadableConcreteRequest<HorizontalNavJiraInnerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "60dc2b047f51b447b5b972b5bfde124e040a46b15be63e8337ebb05a87d88095",
    "metadata": {},
    "name": "HorizontalNavJiraInnerQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider": isJiraCustomTemplatesUxEnabled_provider
    }
  }
};

export default node;

import { createActionsHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import { addCollection } from './actions/add-collection/index.tsx';
import { loadCollections } from './actions/load-collections/index.tsx';
import { deleteCollection as deleteCollectionFromNavigation } from './actions/delete-collection/index.tsx';
import { copyCollection as copyCollectionInNavigation } from './actions/copy-collection/index.tsx';
import { expandSectionForSelectedCollection } from './actions/expand-section-for-selected-collection/index.tsx';
import { updateCollection } from './actions/update-collection/index.tsx';
import type { State, Props } from './types.tsx';

const actions = {
	addCollection,
	loadCollections,
	expandSectionForSelectedCollection,
	updateCollection,
	deleteCollectionFromNavigation,
	copyCollectionInNavigation,
};

type Actions = typeof actions;

const initialState: State = {
	collections: {
		data: [],
		error: null,
		initialized: false,
		isLoading: false,
	},
};

export const NavigationCollectionsStore = createStore({
	initialState,
	actions,
	name: 'PolarisNavigationCollectionsStore',
});

export const NavigationCollectionsContainer = createContainer<State, Actions, Props>(
	NavigationCollectionsStore,
);

export const useNavigationCollectionsActions = createActionsHook(NavigationCollectionsStore);

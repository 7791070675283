/**
 * @generated SignedSource<<407681f54778640f8952adf0fd133b85>>
 * @relayHash 64c5bc938c2dd48f6f4c3fb8dc1e1fcf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fdb05062554adfed4cfd232e2c5b243192903b19902f3ffb7d643be1b4f99f9e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
};
export type Nav4ProjectsContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};
export type Nav4ProjectsContentQuery = {
  response: Nav4ProjectsContentQuery$data;
  variables: Nav4ProjectsContentQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider: {
    readonly get: () => boolean;
  };
});

import growthRecommendationsFlag_provider from '@atlassian/jira-relay-provider/src/growth-recommendations.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentURL"
},
v2 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v3 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v7 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v8 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v9 = [
  (v5/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "large"
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "kind": "ScalarField",
    "name": "projectId"
  },
  {
    "kind": "ScalarField",
    "name": "isFavourite"
  },
  {
    "kind": "LinkedField",
    "name": "defaultNavigationItem",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "ScalarField",
            "name": "url"
          }
        ],
        "type": "JiraNavigationItem",
        "abstractKey": "__isJiraNavigationItem"
      },
      (v6/*: any*/)
    ]
  },
  {
    "alias": "editProjectConfig",
    "args": (v7/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "args": (v7/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "alias": "editPermission",
    "args": (v7/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  }
],
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Nav4ProjectsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateProject",
            "args": [
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          },
          {
            "alias": "favouriteProjects",
            "args": [
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false,
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PROJECT"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "JiraProject"
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "args": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              }
            ],
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "recentProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  }
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "args": [
              (v3/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      {
        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "GrowthRecQuery",
            "kind": "LinkedField",
            "name": "growthRecommendations",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "product",
                        "value": "jira"
                      },
                      {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "cloudId"
                      },
                      {
                        "kind": "Literal",
                        "name": "useCase",
                        "value": "jiraProjectMenuRecommendations"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "context"
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 2
                  }
                ],
                "kind": "LinkedField",
                "name": "recommendations",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "entityId"
                              }
                            ],
                            "type": "GrowthRecJiraTemplateRecommendation"
                          }
                        ]
                      }
                    ],
                    "type": "GrowthRecRecommendations"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fdb05062554adfed4cfd232e2c5b243192903b19902f3ffb7d643be1b4f99f9e",
    "metadata": {},
    "name": "Nav4ProjectsContentQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider
    }
  }
};
})();

(node as any).hash = "2af9d60c02ebc0f282aeb9d97ae0e710";

export default node;

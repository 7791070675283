/**
 * @generated SignedSource<<41f5aa8c318af2643cc32f9ae9bda285>>
 * @relayHash d4eda917b34a3a18e79bcda5b7b5fe18
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 636c93d37068fcd32bac8ef460fac809f928e4ca6e50641ac6f4e0f68326035d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListQuery } from './src_jiraBusinessListQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "636c93d37068fcd32bac8ef460fac809f928e4ca6e50641ac6f4e0f68326035d",
    "metadata": {},
    "name": "src_jiraBusinessListQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

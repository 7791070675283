/**
 * @generated SignedSource<<16204925dc77c8e9b59f5492269b4e26>>
 * @relayHash 70724814892da20d1cdb1f084fae9212
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 43b144b6e86ec4247cb7811467fc52d8bfced081409990c7db360af4e5632f21

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';
import isSavedViewsColumnOrderSettingsEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-column-order-setting.relayprovider';
import withFilters_provider from '@atlassian/jira-relay-provider/src/saved-views-board-filter-setting.relayprovider';
import isSaveResetViewEnabled_provider from '@atlassian/jira-relay-provider/src/sv-230-save-revert-board-view-settings.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "43b144b6e86ec4247cb7811467fc52d8bfced081409990c7db360af4e5632f21",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardcolumnordersettingrelayprovider": isSavedViewsColumnOrderSettingsEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardfiltersettingrelayprovider": withFilters_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsv230saverevertboardviewsettingsrelayprovider": isSaveResetViewEnabled_provider
    }
  }
};

export default node;

/**
 * @generated SignedSource<<c46cdb38f5ed4037ab511824a676bcb7>>
 * @relayHash 6fca4433ecf3218f764c34a46e6d3aa3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3524c642f066578e17186244aed4946799cccff1343cd8bed8c36e78747209fb

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineQuery } from './src_jiraBusinessTimelineQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3524c642f066578e17186244aed4946799cccff1343cd8bed8c36e78747209fb",
    "metadata": {},
    "name": "src_jiraBusinessTimelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

/**
 * @generated SignedSource<<85dced84581a964f83ac854547462b8a>>
 * @relayHash 387c092e4f184ee2c6d0a31546b476f5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7ee8ecb9a5f293386fc6e9aeaf59b5c53f189e25841eba822d02b3921d3777a8

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type L1MenuItemsQuery$variables = {
  cloudId: string;
  isAppsExpanded?: boolean | null | undefined;
  isDashboardsExpanded?: boolean | null | undefined;
  isFiltersExpanded?: boolean | null | undefined;
  isOperationsExpanded?: boolean | null | undefined;
  isOverviewsExpanded?: boolean | null | undefined;
  isPlansExpanded?: boolean | null | undefined;
  isProjectsExpanded?: boolean | null | undefined;
};
export type L1MenuItemsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurableMenuItems">;
};
export type L1MenuItemsQuery = {
  response: L1MenuItemsQuery$data;
  variables: L1MenuItemsQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider: {
    readonly get: () => boolean;
  };
});

import growthRecommendationsFlag_provider from '@atlassian/jira-relay-provider/src/growth-recommendations.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isAppsExpanded"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isDashboardsExpanded"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isFiltersExpanded"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isOperationsExpanded"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isOverviewsExpanded"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isPlansExpanded"
},
v7 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isProjectsExpanded"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = {
  "kind": "ScalarField",
  "name": "label"
},
v13 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v14 = {
  "kind": "ScalarField",
  "name": "url"
},
v15 = {
  "order": "DESC"
},
v16 = {
  "kind": "Literal",
  "name": "first",
  "value": 15
},
v17 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v18 = {
  "kind": "ScalarField",
  "name": "name"
},
v19 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v21 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v22 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v23 = [
  (v8/*: any*/),
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "concreteType": "JiraAvatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "large"
      }
    ]
  },
  (v18/*: any*/),
  {
    "kind": "ScalarField",
    "name": "projectId"
  },
  (v19/*: any*/),
  {
    "kind": "LinkedField",
    "name": "defaultNavigationItem",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v14/*: any*/)
        ],
        "type": "JiraNavigationItem",
        "abstractKey": "__isJiraNavigationItem"
      },
      (v20/*: any*/)
    ]
  },
  {
    "alias": "editProjectConfig",
    "args": (v21/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v22/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "args": (v21/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v22/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "alias": "editPermission",
    "args": (v21/*: any*/),
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v22/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  }
],
v24 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v25 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v26 = {
  "kind": "ScalarField",
  "name": "filterId"
},
v27 = {
  "kind": "ScalarField",
  "name": "jql"
},
v28 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    },
    {
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v11/*: any*/),
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        (v8/*: any*/)
      ]
    }
  ],
  "type": "JiraCustomFilter"
},
v29 = {
  "kind": "ScalarField",
  "name": "title"
},
v30 = {
  "concreteType": "JiraFavouriteValue",
  "kind": "LinkedField",
  "name": "favouriteValue",
  "plural": false,
  "selections": [
    (v19/*: any*/),
    (v8/*: any*/)
  ]
},
v31 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v8/*: any*/),
          {
            "kind": "ScalarField",
            "name": "planId"
          },
          (v29/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isReadOnly"
          },
          {
            "kind": "ScalarField",
            "name": "planStatus"
          },
          (v30/*: any*/),
          {
            "concreteType": "JiraScenario",
            "kind": "LinkedField",
            "name": "scenario",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "scenarioId"
              },
              (v8/*: any*/)
            ]
          }
        ],
        "type": "JiraPlan"
      },
      (v20/*: any*/)
    ]
  }
],
v32 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v8/*: any*/),
          {
            "kind": "ScalarField",
            "name": "dashboardId"
          },
          (v29/*: any*/),
          (v30/*: any*/)
        ],
        "type": "JiraDashboard"
      },
      (v20/*: any*/)
    ]
  }
],
v33 = [
  (v8/*: any*/),
  (v18/*: any*/),
  {
    "kind": "ScalarField",
    "name": "alertSearchQuery"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "L1MenuItemsQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ConfigurableMenuItems"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "L1MenuItemsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudID",
                "variableName": "cloudId"
              },
              {
                "kind": "Literal",
                "name": "navKey",
                "value": "NAV_SIDEBAR"
              }
            ],
            "concreteType": "JiraUserNavigationConfiguration",
            "kind": "LinkedField",
            "name": "userNavigationConfiguration",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraConfigurableNavigationItem",
                "kind": "LinkedField",
                "name": "navItems",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "menuId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isVisible"
                  }
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateProject",
            "args": [
              (v9/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          },
          {
            "condition": "isAppsExpanded",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v10/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "globalAppNavigationItems",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraNavigationItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v12/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "appId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "appType"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "envLabel"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "iconUrl"
                              },
                              (v13/*: any*/),
                              {
                                "concreteType": "JiraAppSection",
                                "kind": "LinkedField",
                                "name": "sections",
                                "plural": true,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "concreteType": "JiraAppNavigationItemNestedLink",
                                    "kind": "LinkedField",
                                    "name": "links",
                                    "plural": true,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v14/*: any*/),
                                      (v13/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "settingsUrl"
                              },
                              (v14/*: any*/)
                            ],
                            "type": "JiraAppNavigationItem"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v10/*: any*/),
            "concreteType": "JiraWorkManagementGiraOverviewConnection",
            "kind": "LinkedField",
            "name": "jwmOverviews",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "totalCount"
              }
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v10/*: any*/),
            "concreteType": "JiraAdvancedRoadmapsNavigation",
            "kind": "LinkedField",
            "name": "advancedRoadmapsNavigation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "hasEditPermissions"
              },
              {
                "kind": "ScalarField",
                "name": "hasCreateSamplePlanPermissions"
              },
              {
                "kind": "ScalarField",
                "name": "hasGlobalPlansAdminPermissions"
              }
            ]
          }
        ]
      },
      {
        "condition": "isProjectsExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "alias": "favouriteProjects",
                "args": [
                  (v9/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "includeArchivedProjects": false,
                      "sort": (v15/*: any*/),
                      "type": "PROJECT"
                    }
                  },
                  (v16/*: any*/)
                ],
                "concreteType": "JiraFavouriteConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  {
                    "concreteType": "JiraFavouriteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v23/*: any*/),
                            "type": "JiraProject"
                          },
                          (v20/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "args": [
                  (v9/*: any*/),
                  (v25/*: any*/)
                ],
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "recentProjects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v23/*: any*/)
                      }
                    ]
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "args": (v10/*: any*/),
                "concreteType": "JiraWorkManagementLicensing",
                "kind": "LinkedField",
                "name": "jwmLicensing",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "currentUserSeatEdition"
                  }
                ]
              }
            ]
          },
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "GrowthRecQuery",
                "kind": "LinkedField",
                "name": "growthRecommendations",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "fields": [
                          {
                            "kind": "Literal",
                            "name": "product",
                            "value": "jira"
                          },
                          {
                            "kind": "Variable",
                            "name": "tenantId",
                            "variableName": "cloudId"
                          },
                          {
                            "kind": "Literal",
                            "name": "useCase",
                            "value": "jiraProjectMenuRecommendations"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "context"
                      },
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 2
                      }
                    ],
                    "kind": "LinkedField",
                    "name": "recommendations",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": true,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "entityId"
                                  }
                                ],
                                "type": "GrowthRecJiraTemplateRecommendation"
                              }
                            ]
                          }
                        ],
                        "type": "GrowthRecRecommendations"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isFiltersExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "alias": "favouriteFilters",
                "args": [
                  (v9/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "sort": (v15/*: any*/),
                      "type": "FILTER"
                    }
                  },
                  (v16/*: any*/)
                ],
                "concreteType": "JiraFavouriteConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  {
                    "concreteType": "JiraFavouriteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v26/*: any*/),
                              (v18/*: any*/),
                              (v8/*: any*/),
                              (v19/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/)
                            ],
                            "type": "JiraFilter",
                            "abstractKey": "__isJiraFilter"
                          },
                          (v20/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "alias": "recentFilters",
                "args": [
                  (v9/*: any*/),
                  (v25/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "types",
                    "value": [
                      "FILTER"
                    ]
                  }
                ],
                "concreteType": "JiraSearchableEntityConnection",
                "kind": "LinkedField",
                "name": "recentItems",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSearchableEntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v26/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v18/*: any*/),
                                  (v8/*: any*/),
                                  (v19/*: any*/),
                                  (v27/*: any*/),
                                  (v28/*: any*/)
                                ],
                                "type": "JiraFilter",
                                "abstractKey": "__isJiraFilter"
                              }
                            ],
                            "type": "JiraCustomFilter"
                          },
                          (v20/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "args": (v10/*: any*/),
                "concreteType": "JiraSystemFilterConnection",
                "kind": "LinkedField",
                "name": "systemFilters",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSystemFilterEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraSystemFilter",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v18/*: any*/),
                              (v11/*: any*/),
                              (v19/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/)
                            ],
                            "type": "JiraFilter",
                            "abstractKey": "__isJiraFilter"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isPlansExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "alias": "favouritePlans",
                "args": [
                  (v9/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "sort": (v15/*: any*/),
                      "type": "PLAN"
                    }
                  },
                  (v16/*: any*/)
                ],
                "concreteType": "JiraFavouriteConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraFavouriteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": (v31/*: any*/)
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "alias": "recentPlans",
                "args": [
                  (v9/*: any*/),
                  (v25/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "types",
                    "value": [
                      "PLAN"
                    ]
                  }
                ],
                "concreteType": "JiraSearchableEntityConnection",
                "kind": "LinkedField",
                "name": "recentItems",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSearchableEntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": (v31/*: any*/)
                  },
                  (v24/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isDashboardsExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "alias": "dashboardFavourites",
                "args": [
                  (v9/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "sort": (v15/*: any*/),
                      "type": "DASHBOARD"
                    }
                  },
                  (v16/*: any*/)
                ],
                "concreteType": "JiraFavouriteConnection",
                "kind": "LinkedField",
                "name": "favourites",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  {
                    "concreteType": "JiraFavouriteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": (v32/*: any*/)
                  },
                  (v24/*: any*/)
                ]
              },
              {
                "alias": "dashboardRecents",
                "args": [
                  (v9/*: any*/),
                  (v25/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "types",
                    "value": [
                      "DASHBOARD"
                    ]
                  }
                ],
                "concreteType": "JiraSearchableEntityConnection",
                "kind": "LinkedField",
                "name": "recentItems",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSearchableEntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": (v32/*: any*/)
                  },
                  (v24/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isOperationsExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "OpsgenieQuery",
            "kind": "LinkedField",
            "name": "opsgenie",
            "plural": false,
            "selections": [
              {
                "args": (v10/*: any*/),
                "concreteType": "OpsgenieSavedSearches",
                "kind": "LinkedField",
                "name": "savedSearches",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "OpsgenieSavedSearch",
                    "kind": "LinkedField",
                    "name": "sharedWithMe",
                    "plural": true,
                    "selections": (v33/*: any*/)
                  },
                  {
                    "concreteType": "OpsgenieSavedSearch",
                    "kind": "LinkedField",
                    "name": "createdByMe",
                    "plural": true,
                    "selections": (v33/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isOverviewsExpanded",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v10/*: any*/),
                "concreteType": "JiraWorkManagementGiraOverviewConnection",
                "kind": "LinkedField",
                "name": "jwmOverviews",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraWorkManagementGiraOverviewEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraWorkManagementGiraOverview",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7ee8ecb9a5f293386fc6e9aeaf59b5c53f189e25841eba822d02b3921d3777a8",
    "metadata": {},
    "name": "L1MenuItemsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider": growthRecommendationsFlag_provider
    }
  }
};
})();

(node as any).hash = "3924b1f11b61ac6cf33dddcea40b965b";

export default node;

/**
 * @generated SignedSource<<442ad08a387f993ec58c0fc20f3e713e>>
 * @relayHash cd312ec977e35b97cafa621f44a113b6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1dae73ce1663e1e9fdc5ee6684c151529976c00871de9359060a7a9e32df5c19

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarEmbedQuery } from './src_jiraBusinessCalendarEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1dae73ce1663e1e9fdc5ee6684c151529976c00871de9359060a7a9e32df5c19",
    "metadata": {},
    "name": "src_jiraBusinessCalendarEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

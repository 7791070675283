import React from 'react';
import type ForgeServiceDeskQueuePageType from '@atlassian/jira-spa-apps-forge-servicedesk-queue/src/index-old.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/** @deprecated delete file on FG clean up 'migrate-forge-app-project-route-to-entrypoint' */
export const LazyForgeServiceDeskQueuePage = lazyForPaint<typeof ForgeServiceDeskQueuePageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-servicedesk-queue", jiraSpaEntry: "async-forge-spa-servicedesk-queue" */ '@atlassian/jira-spa-apps-forge-servicedesk-queue/src/index-old.tsx'
		),
);

export const ForgeServiceDeskQueuePage = () => (
	<LazyPage
		Page={LazyForgeServiceDeskQueuePage}
		pageId="forge-spa-servicedesk-queue"
		shouldShowSpinner
	/>
);

export default ForgeServiceDeskQueuePage;

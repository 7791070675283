import type { CollectionUUID } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import type { NavigationCollection, State } from '../types.tsx';
import type { RemoteNavigationCollection } from '../../common/types/remote.tsx';

export const updateCollectionsState = (
	state: State,
	collectionUUID: CollectionUUID,
	update: (prev: NavigationCollection) => NavigationCollection,
): State | undefined => {
	let hasFoundCollection = false;

	const nextCollections = state.collections.data.map((navigationCollection) => {
		if (navigationCollection.uuid === collectionUUID) {
			hasFoundCollection = true;
			return update(navigationCollection);
		}

		return navigationCollection;
	});

	if (!hasFoundCollection) {
		// There is nothing to update, bail out
		return undefined;
	}

	return {
		...state,
		collections: {
			...state.collections,
			data: nextCollections,
		},
	};
};

export const transformRemoteNavigationCollection = (
	remoteNavigationCollection: RemoteNavigationCollection,
): NavigationCollection => ({
	...remoteNavigationCollection,
	isExpanded: false,
});

export function sortedInsert<T>(arr: T[], element: T, comparator: (a: T, b: T) => boolean): T[] {
	const result: T[] = [];
	let inserted = false;

	for (let i = 0; i < arr.length; i += 1) {
		if (!inserted && comparator(arr[i], element)) {
			inserted = true;
			result.push(element);
		}

		result.push(arr[i]);
	}

	if (!inserted) {
		result.push(element);
	}

	return result;
}

/**
 * @generated SignedSource<<3143b7cc3742bb6d62fe58f2468d728c>>
 * @relayHash 579cfd0c78411a927471f25f17fe0330
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 769067b6baf24a6b2b3b5ee915eeaf13ea83dd7526bef441d157082ae3b9fe00

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewCalendarQuery } from './src_jiraBusinessOverviewCalendarQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "769067b6baf24a6b2b3b5ee915eeaf13ea83dd7526bef441d157082ae3b9fe00",
    "metadata": {},
    "name": "src_jiraBusinessOverviewCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectIssueNavigatorRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/projectIssueNavigatorRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import { LazyProjectIssueNavigator } from './ui/index-old.tsx';
import PinRedirect from './ui/pin-redirect/index-old.tsx';
import { issueNavigatorPinRedirectPageEntryPoint } from './ui/index.tsx';

export const projectIssueNavigatorRouteEntry = createEntry(projectIssueNavigatorRoute, () => ({
	group: 'issue-navigator',
	...getIssueNavigatorRouteEntryCommon(),
	component: fg('sv_120_migrate_issue_navigator_route_to_entrypoint') ? undefined : PinRedirect,
	skeleton: ProjectIssueNavigatorSkeleton,
	forPaint: fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
		? undefined
		: [LazyAtlassianNavigation, LazyProjectIssueNavigator],
	afterPaint: fg('sv_120_migrate_issue_navigator_route_to_entrypoint') ? undefined : [LazyIssueApp],
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: genericProjectSidebars,
		horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
	},
	resources: [...getCommonProjectResources(), getConsolidationStateResource()],
	layout: createLayout(genericProjectLayout),
	entryPoint: () =>
		fg('sv_120_migrate_issue_navigator_route_to_entrypoint')
			? issueNavigatorPinRedirectPageEntryPoint
			: undefined,
}));

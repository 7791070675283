/**
 * @generated SignedSource<<1da42b4d5f54308f75005ae301585025>>
 * @relayHash 9fffa8196479fbf77d1370e75e64933a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e6ccb3bffcab4c3a07370163d77624e9ee4903399486f799c8c85ff73912b18d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiServiceHubDetailsPageQuery } from './uiServiceHubDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiServiceHubDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e6ccb3bffcab4c3a07370163d77624e9ee4903399486f799c8c85ff73912b18d",
    "metadata": {},
    "name": "uiServiceHubDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { expandSectionForSelectedCollection } from '../expand-section-for-selected-collection/index.tsx';
import { transformRemoteNavigationCollection } from '../utils.tsx';

const normalizeError = (error: unknown) => {
	if (error instanceof Error) {
		return error;
	}
	return new Error('Unexpected error - catched value is not an instance of an Error class');
};

export const loadCollections =
	(): Action<State, Props> =>
	async (
		{ setState, getState, dispatch },
		{ navigationCollectionsRemote, cloudId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!navigationCollectionsRemote ||
			!navigationCollectionsRemote.fetch ||
			getState().collections?.isLoading ||
			getState().collections?.initialized
		) {
			return;
		}

		setState({
			collections: {
				...getState().collections,
				isLoading: true,
				initialized: true,
				error: null,
				data: [],
			},
		});

		try {
			const data = await navigationCollectionsRemote.fetch();
			setState({
				collections: {
					...getState().collections,
					isLoading: false,
					initialized: true,
					error: null,
					data: data
						.map(transformRemoteNavigationCollection)
						.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
				},
			});

			dispatch(expandSectionForSelectedCollection());
			onSuccess?.('loadCollections');
		} catch (maybeError: unknown) {
			const error = normalizeError(maybeError);

			setState({
				collections: {
					isLoading: false,
					initialized: true,
					error,
					data: [],
				},
			});

			onFailure?.(error, 'loadCollections');
		}
	};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { genericProjectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import {
	projectContextResource,
	projectContextResourceWithCache,
} from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSharedNavigationSingleResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { forgeProjectServicedeskRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgeProjectServicedeskRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import { fg } from '@atlassian/jira-feature-gating';
import ForgeProjectPage, { LazyForgeProjectPage } from './ui/project/index-old.tsx';
import { forgeProjectServicedeskPageEntrypoint } from './ui/project-servicedesk/index.tsx';

export const forgeProjectServicedeskRouteEntry: Route = createEntry(
	forgeProjectServicedeskRoute,
	() => ({
		component: fg('migrate-forge-app-project-route-to-entrypoint') ? undefined : ForgeProjectPage,
		layout: genericProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: genericProjectSidebars,
		},
		resources: [
			...getServiceDeskSharedNavigationSingleResources(),
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			forgeProjectModuleResource,
			projectContextResourceWithCache,
			getConsolidationStateResource(),
		],
		forPaint: fg('migrate-forge-app-project-route-to-entrypoint')
			? undefined
			: [LazyAtlassianNavigation, LazyForgeProjectPage],
		entryPoint: () =>
			fg('migrate-forge-app-project-route-to-entrypoint')
				? forgeProjectServicedeskPageEntrypoint
				: undefined,
	}),
);

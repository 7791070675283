/**
 * @generated SignedSource<<f57eec21599cbc3b4e13b943e1cdf733>>
 * @relayHash c6cc35680b14506f776e14210ebb3eb4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cfeca3b4e450271b4592fa738d4ba0a3aa891475551165932cbe34d1ca6ea12b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessAttachmentsQuery } from './src_jiraBusinessAttachmentsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessAttachmentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "cfeca3b4e450271b4592fa738d4ba0a3aa891475551165932cbe34d1ca6ea12b",
    "metadata": {},
    "name": "src_jiraBusinessAttachmentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

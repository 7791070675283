import React, { createContext, useContext, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ViewOptionsType,
	ViewOptionsListType,
} from '../show-menu-item/view-options/types.tsx';
import { ViewOptions } from '../show-menu-item/view-options/constants.tsx';
import messages from '../show-menu-item/messages.tsx';
import type { DisplaySettingsContextType, DisplaySettingsProviderProps } from './types.tsx';
import { DisplaySettingsScope } from './constants.tsx';

// Function to retrieve the list of view options based on the contextName.
// Currently, supports only 'projects' context
const useViewOptionsList = (contextName: string): ViewOptionsListType => {
	const { formatMessage } = useIntl();

	if (contextName === DisplaySettingsScope.PROJECTS) {
		return [
			{
				label: formatMessage(messages.starredAndRecentProjectsLabel),
				value: ViewOptions.STARRED_AND_RECENT,
			},
			{
				label: formatMessage(messages.starredProjectsLabel),
				value: ViewOptions.STARRED,
			},
		];
	}
	return [];
};

const DisplaySettingsContext = createContext<DisplaySettingsContextType | undefined>(undefined);

// Provider component to wrap parts of the sidebar that need access to display settings
// https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/#ati:cloud:jira:navigation-settings
// settingScope is used as {navigationSettingScope}
export const DisplaySettingsProvider = ({
	settingScope,
	children,
}: DisplaySettingsProviderProps) => {
	const [selectedViewOption, setSelectedViewOption] = useState<ViewOptionsType>(
		ViewOptions.STARRED_AND_RECENT,
	);

	const viewOptionsList: ViewOptionsListType = useViewOptionsList(settingScope);

	return (
		<DisplaySettingsContext.Provider
			value={{
				viewOptionsList,
				selectedViewOption,
				setSelectedViewOption,
			}}
		>
			{children}
		</DisplaySettingsContext.Provider>
	);
};

export const useDisplaySettings = () => {
	const context = useContext(DisplaySettingsContext);
	if (!context) {
		throw new Error('useDisplaySettings must be used within a DisplaySettingsProvider');
	}
	return context;
};

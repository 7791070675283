import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskConnectProjectRoute } from '@atlassian/jira-router-routes-connect-routes/src/servicedeskConnectProjectRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getProjectConnectRouteDefaultResources } from './common/index.tsx';
import ConnectProjectPage, { LazyProjectsConnect } from './ui/spa/connect/projects/index-old.tsx';
import { connectProjectsWithoutHorizontalNavPageEntrypoint } from './ui/spa/connect/projects/index.tsx';

export const servicedeskConnectProjectRouteEntry: Route = createEntry(
	servicedeskConnectProjectRoute,
	() => ({
		group: 'servicedesk',
		component: fg('sv-122-migrate-connect-project-route-to-entrypoint')
			? undefined
			: ConnectProjectPage,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		resources: [
			...getProjectConnectRouteDefaultResources(),
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
		forPaint: fg('sv-122-migrate-connect-project-route-to-entrypoint')
			? undefined
			: [LazyAtlassianNavigation, LazyProjectsConnect],
		ufoName: 'jira-connect',
		entryPoint: () =>
			fg('sv-122-migrate-connect-project-route-to-entrypoint')
				? connectProjectsWithoutHorizontalNavPageEntrypoint
				: undefined,
	}),
);

/**
 * @generated SignedSource<<22b9c7096253b8faefc2b9569b905990>>
 * @relayHash 7d72e1d223ce875a03dc1f4c7a1a9caf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b509ff9840b3da6f1f463da41335b5591cfda9783e4b01ebc80e07285118850

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';
import isSavedViewsColumnOrderSettingsEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-column-order-setting.relayprovider';
import withFilters_provider from '@atlassian/jira-relay-provider/src/saved-views-board-filter-setting.relayprovider';
import isSaveResetViewEnabled_provider from '@atlassian/jira-relay-provider/src/sv-230-save-revert-board-view-settings.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6b509ff9840b3da6f1f463da41335b5591cfda9783e4b01ebc80e07285118850",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardcolumnordersettingrelayprovider": isSavedViewsColumnOrderSettingsEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardfiltersettingrelayprovider": withFilters_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsv230saverevertboardviewsettingsrelayprovider": isSaveResetViewEnabled_provider
    }
  }
};

export default node;

const PROJECTS = 'projects' as const;

export const DisplaySettingsScope = {
	PROJECTS,
} as const;

const VIEW_OPTION = 'VIEW_OPTION' as const;

export const DisplaySettingsProperties = {
	VIEW_OPTION,
} as const;

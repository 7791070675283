/**
 * @generated SignedSource<<558dc025f982ba477ef4085dfd4bc76d>>
 * @relayHash b2e0402fc2be3d3bbd9c714d7a1f4f3e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 35307c09b0ced9ab5c91e7ef6be2ca8cd096624d78b1b2a076f715221c1aa113

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineEmbedQuery } from './src_jiraBusinessTimelineEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "35307c09b0ced9ab5c91e7ef6be2ca8cd096624d78b1b2a076f715221c1aa113",
    "metadata": {},
    "name": "src_jiraBusinessTimelineEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
